// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
//Uploaders
import {
    tenantImportReset,
    tenantValidationErrorDownload,
    crmReset,
    crmDownloadErrorMapping,
    userValidationErrorDownload,
    userImportReset,
    recordsValidationErrorDownload,
    fundsValidationErrorDownload,
    fundAssetsValidationErrorDownload,
    fundDonorsValidationErrorDownload,
    downloadRecordFyErrorSheet,
    fundImpactsValidationErrorDownload,
    fundImpactsFyAssetsValidationErrorDownload,
    fundImpactsFyDownloadsValidationErrorDownload,
    fundPerformancesValidationErrorDownload,
    fundPerformancesFyValidationErrorDownload,
    downloadRecordGivingErrorSheet,
    recordImportReset,
    fundImpactImportReset,
} from '@ovrture/react-redux';
import { downloadFile } from '../../../../helpers/downloadFile/downloadFile';
import { useHistory } from 'react-router-dom';

const ImportDataModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        tenantDownloadMapping,
        downloadMapping,
        userValidationErrorDownload_,
        assetImpactErrorDownload,
        assetImpactErrorDownloadFyDownloads,
        assetImpactErrorDownloadFyAssets,
        assetImpactErrorDownloadErrorSheet,
    } = useSelector((state) => ({
        tenantDownloadMapping: state?.tenantImport?.tenantValidationErrorDownload,
        downloadMapping: state?.crm?.downloadMapping,
        userValidationErrorDownload_: state?.userImport?.userValidationErrorDownload,
        assetImpactErrorDownload: state?.recordImport?.recordsValidationErrorDownload,
        assetImpactErrorDownloadFyDownloads: state?.fundImpactImport?.fundImpactsFyDownloadsValidationErrorDownload,
        assetImpactErrorDownloadFyAssets: state?.fundImpactImport?.fundImpactsFyAssetsValidationErrorDownload,
        assetImpactErrorDownloadErrorSheet: state?.recordImport?.downloadRecordGivingErrorSheet,
    }));

    useEffect(() => {
        if (tenantDownloadMapping) {
            downloadFile(
                tenantDownloadMapping?.data,
                'application/zip',
                tenantDownloadMapping?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(tenantImportReset());
        }

        if (downloadMapping) {
            downloadFile(
                downloadMapping?.data,
                'application/zip',
                downloadMapping?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(crmReset());
        }

        if (userValidationErrorDownload_) {
            downloadFile(
                userValidationErrorDownload_?.data,
                'application/zip',
                userValidationErrorDownload_?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(userImportReset());
        }

        if (assetImpactErrorDownload) {
            downloadFile(
                assetImpactErrorDownload?.data,
                'application/zip',
                assetImpactErrorDownload?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(recordImportReset());
        }

        if (assetImpactErrorDownloadFyAssets) {
            downloadFile(
                assetImpactErrorDownloadFyAssets?.data,
                'application/zip',
                assetImpactErrorDownloadFyAssets?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundImpactImportReset());
        }
        if (assetImpactErrorDownloadFyDownloads) {
            downloadFile(
                assetImpactErrorDownloadFyDownloads?.data,
                'application/zip',
                assetImpactErrorDownloadFyDownloads?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(fundImpactImportReset());
        }
        if (assetImpactErrorDownloadErrorSheet) {
            downloadFile(
                assetImpactErrorDownloadErrorSheet?.data,
                'application/zip',
                assetImpactErrorDownloadErrorSheet?.headers['content-disposition'].split('filename=')[1]
            );
            dispatch(recordImportReset());
        }
    }, [
        downloadMapping,
        userValidationErrorDownload_,
        assetImpactErrorDownload,
        assetImpactErrorDownloadFyDownloads,
        assetImpactErrorDownloadFyAssets,
        assetImpactErrorDownloadErrorSheet,
        tenantDownloadMapping,
    ]);

    const downloadAnnotationSheet = (errorId, data) => {
        try {
            let validationDispatch = props?.data?.validationDispatch;
            if (props?.data?.KeyName) {
                props?.data?.handleDownloadError({
                    pkXLSErrorId: errorId,
                    isPreflight: false,
                    isUpdater: props?.data?.isUpdater,
                });
            } else if (validationDispatch) {
                if (validationDispatch?.allDispacthes[0] === 'recordsValidationErrorDownload') {
                    dispatch(
                        recordsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundsValidationErrorDownload') {
                    dispatch(
                        fundsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'tenantValidationErrorDownload') {
                    dispatch(
                        tenantValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            orgId: props?.data?.orgId,
                            importModuleId: props?.data?.importModuleId,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'crmValidationErrorDownload') {
                    dispatch(
                        crmDownloadErrorMapping({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            type: data?.data?.tabType,
                            //isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundAssetsValidationErrorDownload') {
                    dispatch(
                        fundAssetsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundDonorsValidationErrorDownload') {
                    dispatch(
                        fundDonorsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'downloadRecordFyErrorSheet') {
                    dispatch(
                        downloadRecordFyErrorSheet({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsValidationErrorDownload') {
                    dispatch(
                        fundImpactsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsFyAssetsValidationErrorDownload') {
                    dispatch(
                        fundImpactsFyAssetsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundImpactsFyDownloadsValidationErrorDownload') {
                    dispatch(
                        fundImpactsFyDownloadsValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundPerformancesValidationErrorDownload') {
                    dispatch(
                        fundPerformancesValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'fundPerformancesFyValidationErrorDownload') {
                    dispatch(
                        fundPerformancesFyValidationErrorDownload({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                } else if (validationDispatch?.allDispacthes[0] === 'downloadRecordGivingErrorSheet') {
                    dispatch(
                        downloadRecordGivingErrorSheet({
                            pkXLSErrorId: errorId,
                            isPreflight: false,
                            isUpdater: validationDispatch?.isUpdater,
                        })
                    );
                }
            } else {
                dispatch(
                    userValidationErrorDownload({
                        pkXLSErrorId: errorId,
                        isPreflight: false,
                        isUpdater: props?.data?.isUpdater,
                    })
                );
            }
        } catch (error) {}
    };

    return (
        <>
            <Row>
                <Col lg={12}>
                    {props?.data?.data?.code === 406 ? (
                        <div>
                            <p>The following results were achieved by importing this data file:</p>
                            <p>
                                <b>{props?.data?.data?.failedRowCount}</b> rows have invalid characters, please remove
                                them and try again. Download the annotated spreadsheet to learn which row, correct the
                                data and test again.
                            </p>
                            <div className="button-list d-flex justify-content-center mt-2">
                                <ButtonElement
                                    name="Download Annotated Spreadsheet"
                                    variant="outline-primary"
                                    type="button"
                                    onClick={() =>
                                        downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId, props?.data)
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>The following results were achieved by importing this data file:</p>
                            {(props?.data?.data?.successRowCount > 0 || props?.data?.data?.success) && (
                                <div className="success">
                                    <p>
                                        <b>
                                            {props?.data?.data?.failedRowCount === 0 || props?.data?.data?.success
                                                ? 'All'
                                                : props?.data?.data?.successRowCount}
                                        </b>{' '}
                                        rows <b>passed</b> the validation process.
                                    </p>
                                </div>
                            )}
                            {props?.data?.data?.failedRowCount > 0 && (
                                <div className="fail hide">
                                    <p>
                                        <b>
                                            {props?.data?.data?.successRowCount === 0
                                                ? 'All'
                                                : props?.data?.data?.failedRowCount}
                                        </b>{' '}
                                        rows <b>failed</b> the validation process; download the failed rows to learn
                                        why, correct the data, and upload again.
                                    </p>
                                    <div className="button-list d-flex justify-content-center mt-2">
                                        <ButtonElement
                                            name="Download Failed Rows"
                                            variant="outline-primary"
                                            type="button"
                                            onClick={() =>
                                                downloadAnnotationSheet(props?.data?.data?.pkXlsErrorId, props?.data)
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <div className="modal-footer d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        if (
                            props?.data?.onRedirect &&
                            ((props?.data?.data?.failedRowCount > 0 && props?.data?.data?.successRowCount > 0) ||
                                props?.data?.data?.failedRowCount === 0 ||
                                props?.data?.data?.success)
                        ) {
                            props?.data?.onRedirect();
                        }

                        if (!props?.data?.isNotification) props.data.resetForm();
                        if (props?.data?.KeyConponent) {
                            modalDispatch({
                                type: modalActions.RESET_MODAL,
                            });
                        } else {
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            });
                        }
                    }}
                />
            </div>
        </>
    );
};

export default ImportDataModal;
