//Platform Admin Modal Content
import ResetPasswordPlatform from '../pages/platform/account/ResetPasswordModal';
import ResetPasswordCommon from '../pages/commonpages/commonModals/ResetPasswordModal';
import PrompDialougeModal from '../pages/commonpages/commonModals/PrompDialougeModal';
import FundDescriptionModal from '../pages/platform/editElements/reportData/FundDescriptionModal';
import AddressDefaultModal from '../pages/platform/editElements/core/AddressDefaultModal';
import VimeoAccountModal from '../pages/platform/editElements/core/VimeoAccountModal';
import BroadcastModal from '../pages/platform/editElements/core/BroadcastModal';
import BrandName from '../pages/platform/editElements/brands/BrandName';
import EditBrandDetails from '../pages/platform/editElements/brands/EditBrandDetails';
import EditContentPanel from '../pages/platform/editElements/contentPanels/EditContentPanel';
import FundDonor from '../pages/platform/editElements/reportData/FundDonorModal';
import FundImpactModal from '../pages/platform/editElements/reportData/FundImpactModal';
import FundPerformance from '../pages/platform/editElements/reportData/FundPerformanceModal';
import FundTypeModal from '../pages/platform/editElements/reportData/FundTypeModal';
import NewFundModal from '../pages/platform/editElements/reportData/NewFundModal';
import ImpactTypeModal from '../pages/platform/editElements/reportData/ImpactTypeModal';
import NewImpactModal from '../pages/platform/editElements/reportData/NewImpactModal';
import ReportPanelMapModal from '../pages/platform/editElements/reportPanels/ReportPanelMapModal';
import ReportPanelHtmlModal from '../pages/platform/editElements/reportPanels/ReportPanelHtmlModal';
import SitePrintBridgeModal from '../pages/platform/editElements/contentPanels/SitePrintBridgeModal';
import ReportprintBridgeModal from '../pages/platform/editElements/reportPrintBridge/ReportprintBridgeModal';
import EnableDisableMultiStepForm from '../pages/platform/dashboard/EnableDisableMultiStepForm';
import ClearDeleteMultiStepForm from '../pages/platform/dashboard/ClearDeleteMultiStepForm';
import AccountLockedModal from '../pages/public/AccountLockedModal';
//System Admin Modal Content
import CopyUrl from '../pages/commonpages/records/CopyUrlModal';
import Snapshots from '../pages/commonpages/analytics/SnapshotsModal';
import EditorsList from '../pages/commonpages/records/EditorsListModal';
import UserSettingResetPassword from '../pages/system/users/UserSettingResetPasswordModal';
import EditMembership from '../pages/system/users/EditMembershipModal';
import AddMember from '../pages/system/users/AddMemberModal';
import NewGroup from '../pages/system/users/NewGroupModal';
import RenameGroup from '../pages/system/users/RenameGroupModal';
import ExportUsersDataModal from '../pages/commonpages/exports/ExportUsersDataModal';
import ExportRecords from '../pages/commonpages/exports/ExportRecordsModal';
import ExportUserActivity from '../pages/commonpages/exports/ExportUserActivityModal';
import ExportFundDetailsModal from '../pages/commonpages/exports/ExportFundDetailsModal';
import ExportFyPerformance from '../pages/commonpages/exports/ExportFyPerformanceModal';
import ExportFyDonors from '../pages/commonpages/exports/ExportFyDonorsModal';
import ExportFyImpacts from '../pages/commonpages/exports/ExportFyImpactsModal';
import ExportFyDistributionList from '../pages/commonpages/exports/ExportFyDistributionListModal';
import ExportSiteDistributionList from '../pages/commonpages/exports/ExportSiteDistributionListModal';
import ExportFyReportsPrintBridge from '../pages/commonpages/exports/ExportFyReportsPrintBridgeModal';
import ExportRecordBasedDataAndSnapshots from '../pages/commonpages/exports/ExportRecordBasedDataAndSnapshotsModal';
import ExportSystemWideDashboardData from '../pages/commonpages/exports/ExportSystemWideDashboardDataModal';
import ExportArchiveData from '../pages/commonpages/exports/ExportArchiveDataModal';
import LinkedRecords from '../pages/commonpages/records/LinkedRecordsModal';
import AddDonor from '../pages/commonpages/contentPanels/reportData/AddDonorModal';
import SessionRecording from '../pages/commonpages/analytics/SessionRecordingModal';
import ManageDownloads from '../pages/commonpages/records/ManageDownloadsModal';
import CreateAccessKey from '../pages/system/settings/CreateAccessKeyModal';
import DeleteAccessKey from '../pages/system/settings/DeleteAccessKeyModal';
import FilterByBrand from '../pages/system/contentPanels/FilterByBrand';
import UpdateTimer from '../pages/system/contentPanels/UpdateTimerModal';
import SetBroadcastTimer from '../pages/system/contentPanels/SetBroadcastTimerModal';
import DeleteMultiStepForm from '../pages/system/users/DeleteMultiStepForm';

//Commonform
import LinkFunds from '../pages/commonpages/records/LinkFundsModal';
import BtdGreetingMessage from '../pages/commonpages/contentPanels/builderDefaults/BtdGreetingMessageModal';
import BtdGreetingImage from '../pages/commonpages/contentPanels/builderDefaults/BtdGreetingImageModal';
import PreflightData from '../pages/commonpages/commonModals/PreflightDataModal';
import ImportData from '../pages/commonpages/contentPanels/reportData/ImportDataModal';
import SelectBrand from '../components/modal/form/SelectBrand';
import BuildReport from '../pages/commonpages/reports/BuildReportModal';
import ProgressBarStatus from '../pages/commonpages/commonModals//ProgressBarStatusModal';
import NewFolder from '../pages/commonpages/commonModals//NewFolderModal';
import RenameFolder from '../pages/commonpages/commonModals/RenameFolderModal';
import DeleteFolder from '../pages/commonpages/commonModals/DeleteFolderModal';
import RenameContentPanel from '../pages/commonpages/contentPanels/RenameContentPanelModal';
import ContentPanelPreview from '../pages/commonpages/contentPanels/ContentPanelPreviewModal';
import AssetPanelPreview from '../pages/commonpages/assets/AssetPanelPreviewModal';
import RenameAsset from '../pages/commonpages/assets/RenameAssetModal';
import SelectContentPanelModal from '../pages/commonpages/contentPanels/builderDefaults/SelectContentPanelModal';
import SelectNewLocationModal from '../pages/commonpages/contentPanels/builderDefaults/SelectNewLocationModal ';
import ReplaceAssetModal from '../pages/commonpages/records/ReplaceAssetModal';
import AddNewFyData from '../pages/commonpages/contentPanels/reportData/AddNewFyDataModal';
import DeleteActions from '../pages/system/users/DeleteActions';
import BulkSuspendUnSuspend from '../pages/system/users/BulkSuspendUnSuspend';
import RestoreActions from '../pages/system/users/RestoreActions';
import RestoreOriginalOwner from '../pages/system/users/RestoreOriginalOwner';
import DeleteGroup from '../pages/system/users/DeleteGroup';
import CreateEditConfirmation from '../pages/system/users/CreateEditConfirmation';
import ImageCropper from '../components/cropper/ImageCropper';
import BrandAdminSetting from '../pages/system/settings/BrandAdminSettingModal';
import MultiStepResetPassword from '../pages/commonpages/portfolio/MultiStepResetPassword';
import ManageDownloadReplaceAssetModal from '../pages/commonpages/portfolio/ManageDownloadReplaceAssetModal';
import AssetUploadModal from '../pages/commonpages/assets/AssetUploadModal';
import ChangeLocationModal from '../pages/commonpages/assets/ChangeLocationModal';
import SelectTemplateWithPreviewModal from '../pages/commonpages/contentPanels/selectTemplateWithPreviewModal';
import PortfolioResetPasscode from '../pages/commonpages/portfolio/PortfolioResetPasscode';
import ChangeLocationConfirmationModal from '../pages/commonpages/contentPanels/ChangeLocationConfirmationModal';
import FundPreviewModal from '../pages/commonpages/contentPanels/reportData/FundPreviewModal';
import ImpactSortModal from '../pages/commonpages/reportData/ImpactSortModal';
import DuplicateModal from '../pages/commonpages/records/DuplicateModal';
import PublishEditedContentPanelMultiStepForm from '../pages/commonpages/contentPanels/PublishEditedContentPanelMultiStepForm';
import BroadcastPublishMultiStepForm from '../pages/system/contentPanels/BroadcastPublishMultiStepForm';
import ReplaceAssetEditorModal from '../helpers/editor/modal/ReplaceAssetEditorModal';
import SaveDraftModal from '../pages/commonpages/contentPanels/SaveDraftModal';
//editors related modals
import AddOrChangeSlide from '../helpers/editor/modal/AddOrChangeSlide';
import PrintBridgeHeading from '../helpers/editor/modal/PrintBridgeHeading';
import PrintPreview from '../helpers/editor/modal/PrintPreview';
import ChangeCoverImage from '../helpers/editor/modal/ChangeCoverImage';
import AddContentPanelModal from '../helpers/editor/modal/AddContentPanelModal';
import PrintReportElements from '../helpers/editor/modal/PrintReportElements';
import CloudDetailsUpdateMultiStepForm from '../pages/platform/platformDetails/CloudDetailsUpdateMultiStepForm';
import ReplaceScriptCodeModal from '../helpers/editor/modal/ReplaceScriptCodeModal';
import EmbedVideoModal from '../helpers/editor/modal/EmbedVideoModal';
import ApproveMultiStepModalForm from '../pages/commonpages/reviewAndApproval/ApproveMultiStepModalForm';
import DeleteUserModal from '../pages/platform/users/DeleteUserModal';
import UserSuspendUnsuspendModal from '../pages/platform/users/UserSuspendUnsuspendModal';
import ReplaceAssetEditorModalCommon from '../helpers/editor/modal/ReplaceAssetEditorModalCommon';
import NavigationModal from '../helpers/editor/modal/NavigationModal';
import OneEditorWarning from '../helpers/editor/modal/OneEditorWarning';
import RestoreDeletedDataActionModal from '../pages/commonpages/deletedData/RestoreDeletedDataActionModal';
import SelectAssetFolderModel from '../pages/commonpages/assets/SelectAssetFolderModal';
import ExportSystemWideSnapshotsDataModal from '../pages/commonpages/exports/ExportSystemWideSnapshotsDataModal';
import ConfigureDataModal from '../pages/commonpages/apiConnection/ConfigureDataModal';
import CrmMappingUploadModal from '../pages/system/users/CrmMappingUploadModal';
import CrmTestMultiStepForm from '../pages/system/CrmTestMultiStepForm';
import RestoreDeletedDataActionModalPA from '../pages/platform/deletedData/RestoreDeletedDataActionModalPA';
import PortfolioRevealPasscodeModal from '../pages/commonpages/portfolio/PortfolioRevealPasscode';
import MagicLinkCopyUrlModal from '../pages/commonpages/records/MagicLinkCopyUrl';
import ChangeLogCommonModule from '../pages/commonpages/changeLog/ChangeLogCommonModule';
import RegisteredDevicesModal from '../pages/commonpages/account/RegisteredDevicesModal';
import BulkSoftDeleteSite from '../pages/system/users/BulkSoftDeleteSite';
import ResetMFAModal from '../pages/commonpages/commonModals/ResetMFA';
import ExportChangeLogData from '../pages/commonpages/exports/ExportChangeLogData';
import ChangeLogCommonModulePlatform from '../pages/commonpages/changeLog/ChangeLogCommonModulePlatform';
import ChangeLogViewDetails from '../pages/commonpages/changeLog/ChangeLogViewDetails';
import SystemAdminResetPasswordWithMfa from '../pages/system/settings/SystemAdminResetPasswordWithMfa';
import BuildReportWithReportUpdateOnModal from '../pages/commonpages/reports/BuildReportWithReportUpdateOnModal';
import SelectFundReportWithReportUpdateOnModal from '../pages/commonpages/reports/SelectFundReportWithReportUpdateOnModal';
import DuplicateContentPanelModal from '../pages/commonpages/contentPanels/DuplicateContentPanelModal';
import DeleteUserConfirmationModal from '../pages/system/users/DeleteUserConfirmationModal';
import ImportTenantModal from '../pages/platform/tenantCopy/ImportTenantModal';

const modal = {
    platformAdmin: {
        tenantCopyUploader: (props) => [
            {
                modalTitle: props?.data?.title,
                modalSize: 'lg',
                modalContent: <TenantCopyUploader hideModal={props?.modalAction} data={props.data} />,
            },
            console.log(props?.data),
        ],
        accountLocked: (props) => [
            {
                modalTitle: 'Account Locked',
                modalSize: 'small',
                modalContent: <AccountLockedModal data={props.data} />,
                modalMessageWarning: 'Note: ',
                modalMessageText:
                    'Once this time has elapsed, and if necessary, click "Forgot Password?" to reset your password.',
            },
        ],
        status: (props) => [
            {
                modalTitle: 'Disable',
                modalContent:
                    'Are you sure you want to proceed with disabling this system? All admin and user accounts will lose access to platform.',
                modalButton: 'Proceed',
                crossIcon: true,
            },
        ],
        enableDisableMultiStepModal: (props) => [
            {
                modalTitle: props?.data?.orgName ? props.data.status + ' > ' + props.data.orgName : props.data.status,
                modalContent: <EnableDisableMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.status === 'Disable'
                        ? 'Disabling this system will prevent all admin and users accounts from accessing the platform.'
                        : 'Enabling this system will provide all admin and users accounts access to the platform.',

                modalSize: 'lg',
            },
        ],
        cloudUpdateMultiStepModal: (props) => [
            {
                modalTitle: 'Cloud Details',
                modalContent: <CloudDetailsUpdateMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
            },
        ],
        clearDeleteMultiStepModal: (props) => [
            {
                modalTitle: props.data.title,
                modalContent: <ClearDeleteMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props.data.item === 'clear'
                        ? 'You are about to clear all system data permanently; this can not be undone.'
                        : 'You are about to delete all related users, digital assets, content, and data.',
            },
        ],
        login: (props) => [
            {
                modalTitle: 'Admin Sign In',
                modalContent:
                    'You are about to sign in as a System Admin for a tenant system. To return to the Platform Admin account, you will have to sign out and sign back in. Proceed?',
                modalButton: 'Proceed',
            },
        ],
        restore: (props) => [
            {
                modalTitle: 'Restore System',
                modalContent: 'The following system will restore all the data.',
                modalButton: 'Proceed',
            },
        ],
        communicationDelete: (props) => [
            {
                modalTitle: 'Delete Email Template',
                modalContent: 'Are you sure you want to delete this email template?',
                modalButton: 'Proceed',
            },
        ],
        suspendedUser: (props) => [
            {
                modalTitle: 'Suspend User',
                modalContent: 'Are you sure you want to suspend this user?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
            },
        ],
        unSuspendedUser: (props) => [
            {
                modalTitle: 'Unsuspend User',
                modalContent: 'Are you sure you want to unsuspend this user?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
            },
        ],
        deleteUser: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent: 'Are you sure you want to delete this user?',
                modalButton: 'Proceed',
            },
        ],
        deleteUserBulk: (props) => [
            {
                modalTitle: 'Delete User(s)',
                modalContent: <DeleteUserModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Proceed',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreActionsPA: (props) => [
            {
                modalTitle: props?.data?.title && props.data.title,
                modalContent: props?.data?.message && props.data.message,
                modalButton: 'Proceed',
            },
        ],
        deleteActionsPA: (props) => [
            {
                modalTitle: props?.data?.title && props.data.title,
                modalContent: props?.data?.message && props.data.message,
                modalButton: 'Proceed',
            },
        ],
        restoreDeletedDataActionModalPA: (props) => [
            {
                modalTitle: 'Restore',
                modalContent: <RestoreDeletedDataActionModalPA hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Proceed',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        userSuspendUnsuspendBulk: (props) => [
            {
                modalTitle: props?.data?.name === 'bulkSuspend' ? 'Suspend User(s)' : 'Unsuspend User(s)',
                modalContent: <UserSuspendUnsuspendModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Proceed',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteDevice: (props) => [
            {
                modalTitle: 'Delete Device',
                modalContent: 'Are you sure you want to delete this device permanently?',
                modalButton: 'Delete',
            },
        ],
        resetPasswordPlatform: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ResetPasswordPlatform hideModal={props?.modalAction} data={props?.data} />,
            },
        ],
        fundDescription: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Description',
                modalContent: <FundDescriptionModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundDonor: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Donors',
                modalContent: <FundDonor hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundImpact: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Impact',
                modalContent: <FundImpactModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundPerformance: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Performance',
                modalContent: <FundPerformance hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        fundType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Fund Type',
                modalContent: <FundTypeModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        importTenantCopy: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ImportTenantModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        newFundType: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <NewFundModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        impactType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Report Data > Impact Type',
                modalContent: <ImpactTypeModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        newImpactType: (props) => [
            {
                modalTitle: props?.data?.title + ' > Add New',
                modalContent: <NewImpactModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelMap: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportPanelMapModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelHtml: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportPanelHtmlModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        reportPanelHtmlDelete: (props) => [
            {
                modalTitle: 'Delete RDT Panel Html',
                modalContent: 'Are you sure you want to delete this rdt panel html?',
                modalButton: 'Proceed',
            },
        ],
        reportPanelHtmlMapDelete: (props) => [
            {
                modalTitle: "Delete Mapped RDT Panel's html",
                modalContent: "Are you sure you want to delete this mapped rdt panel's html?",
                modalButton: 'Proceed',
            },
        ],
        addressDefault: (props) => [
            {
                modalTitle: props?.data?.title + ' > Address Defaults',
                modalContent: <AddressDefaultModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        vimeo: (props) => [
            {
                modalTitle: props?.data?.title + ' > Vimeo Account',
                modalContent: <VimeoAccountModal hideModal={props?.modalAction} data={props.data} />,
            },
        ],
        broadcast: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <BroadcastModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg ',
            },
        ],
        broadcastDelete: (props) => [
            {
                modalTitle: 'Delete Broadcast',
                modalContent: props?.data?.message && props.data.message,
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        newPalette: (props) => [
            {
                modalTitle: props?.data?.title + ' > Palette > Add New',
                modalContent: 'You are about to create a new palette.',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        paletteDelete: (props) => [
            {
                modalTitle: 'Delete Palette',
                modalContent: 'Are you sure you want to delete this palette?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        brandDelete: (props) => [
            {
                modalTitle: 'Delete Brand',
                modalContent: 'Are you sure you want to delete this brand?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        editBrandDetails: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <EditBrandDetails hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        brandName: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <BrandName hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        editPanel: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <EditContentPanel hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'lg',
            },
        ],
        deleteContentPanel: (props) => [
            {
                modalTitle: 'Delete Content Panel',
                modalContent: 'Are you sure you want to delete this content panel?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        editStdPb: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <SitePrintBridgeModal hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Proceed',
                modalSize: 'lg',
            },
        ],
        editReportPb: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ReportprintBridgeModal hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Proceed',
                modalSize: 'lg',
            },
        ],
    },
    systemAdmin: {
        crmTestMultiStep: (props) => [
            {
                modalTitle: props.data.title,
                modalContent: <CrmTestMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg CrmTestMultiStepFormModal',
            },
        ],
        approveMultiStepModal: (props) => [
            {
                modalTitle: 'Approve ' + (props?.data?.name === 'Site' ? 'Site(s)' : 'Report(s)'),
                modalContent: <ApproveMultiStepModalForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        login: (props) => [
            {
                modalTitle: 'Sign In',
                modalContent:
                    'You are about to login as another User/Admin. To return to the System Admin account you will have to logout and log back in. Proceed?',
                modalButton: 'Proceed',
                modalSize: 'small',
            },
        ],
        suspendUnsuspendUser: (props) => [
            {
                modalTitle:
                    (props.data.name === 'bulksuspended' || props.data.name === 'suspendUser'
                        ? 'Suspend'
                        : 'Unsuspend') +
                    (props.data.name === 'bulksuspended' || props.data.name === 'bulkUnsuspended' ? ' Users' : ' User'),
                modalContent: <BulkSuspendUnSuspend hideModal={props?.modalAction} data={props.data} />,
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'If you elect to ' +
                    (props.data.name === 'bulksuspended' || props.data.name === 'suspendUser'
                        ? ' suspend ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ', all sites, records and reports built by ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ' will remain active. However, ' +
                          (props.data.name === 'bulksuspended' ? 'these users' : 'this user') +
                          ' will not be able to log back in to the platform until their ' +
                          (props.data.name === 'bulksuspended' ? 'accounts are' : 'account is') +
                          ' reinstated.'
                        : ' unsuspend ' +
                          (props.data.name === 'bulkUnsuspended' ? 'these users' : 'this user') +
                          ', they will be granted access to the platform and have their full permissions restored.'),
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteUser: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <DeleteUserConfirmationModal hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border',
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText: props?.data?.modalMessageText
                    ? props?.data?.modalMessageText
                    : 'This action is irreversible; proceed with caution.',
                modalSize: 'small',
            },
        ],
        deleteMultiStepModal: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent: <DeleteMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you delete this/these user(s), you will be able to transfer all of their related sites, reports, content and data to another user prior to permanently removing this user from the system.',
                modalSize: 'lg',
            },
        ],
        canNotProceed: (props) => [
            {
                modalTitle: 'Delete User',
                modalContent:
                    'Cannot proceed with delete function as there are no users available to transfer the records.',
                modalCancelButton: 'Close',
                modalSize: 'small all-user-delete-modal',
            },
        ],
        deleteActions: (props) => [
            {
                modalTitle:
                    props?.data?.name === 'softDeleteUserandGroups' ||
                    props?.data?.name === 'softDeleteUser' ||
                    props?.data?.name === 'bulkSoftDeleteUser' ||
                    props?.data?.name === 'bulkDeleteUserandGroups' ||
                    props?.data?.name === 'bulkHardDeleteRecords' ||
                    props?.data?.name === 'hardDeleteRecord' ||
                    props?.data?.name === 'hardDeleteReport' ||
                    props?.data?.name === 'hardDeleteSite'
                        ? 'Delete Confirmation'
                        : props?.data?.name === 'bulkRecordsDelete'
                        ? 'Test'
                        : '',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning:
                    props?.data?.name === 'bulkHardDeleteRecords' ||
                    props?.data?.name === 'hardDeleteRecord' ||
                    props?.data?.name === 'hardDeleteReport' ||
                    props?.data?.name === 'hardDeleteSite' ||
                    props?.data?.name === 'softDeleteUser' ||
                    props?.data?.name === 'bulkSoftDeleteUser'
                        ? 'Warning: '
                        : '',
                modalMessageText:
                    props?.data?.name === 'bulkHardDeleteRecords' ||
                    props?.data?.name === 'hardDeleteRecord' ||
                    props?.data?.name === 'hardDeleteReport' ||
                    props?.data?.name === 'hardDeleteSite'
                        ? 'This action is irreversible; proceed with caution.'
                        : props?.data?.name === 'softDeleteUser' || props?.data?.name === 'bulkSoftDeleteUser'
                        ? 'Proceeding will remove the selected User(s) from system license count, data tables, and exports. Soft-deleted Users and associated Sites/Reports cannot be edited; associated Records must be transfered or deleted. The System Admin can restore or permanently purge soft-deleted Users.'
                        : '',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteRecord: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.name === 'bulkSitesDelete'
                        ? 'If deleted, this/these site(s) will be permanently purged from the system.'
                        : props?.data?.name === 'bulkReportsDelete'
                        ? 'If deleted, this/these report(s) will be permanently purged from the system.'
                        : 'Proceeding will remove the selected Record(s) from data tables and exports. Soft-deleted Records and associated Sites/Reports remain active and cannot be edited. The System Admin can restore or permanently purge soft-deleted Records.',
                modalSize: 'small',
            },
        ],
        portfolioRevealPasscode: (props) => [
            {
                modalTitle: 'Reveal Passcode',
                modalContent: <PortfolioRevealPasscodeModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'sm',
            },
        ],
        userSettingResetPassword: (props) => [
            {
                modalTitle: 'Reset Password',
                modalContent: (
                    <UserSettingResetPassword
                        hideModal={props?.modalAction}
                        orgId={props?.data?.orgId}
                        id={props?.data?.id}
                        role={props?.data?.role}
                    />
                ),
                modalSize: 'lg',
            },
        ],
        systemAdminResetPasswordWithMfa: (props) => [
            {
                modalTitle: 'Reset Password',
                modalContent: <SystemAdminResetPasswordWithMfa hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border',
            },
        ],
        viewRegisteredDevices: (props) => [
            {
                modalTitle: 'Registered Devices',
                modalContent: <RegisteredDevicesModal hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],
        portfolioResetPasscode: (props) => [
            {
                modalTitle: 'Reset Passcode',
                modalContent: <PortfolioResetPasscode id={props?.data?.id} hideModal={props?.modalAction} />,
                modalSize: 'lg',
            },
        ],

        crmMappingUpload: (props) => [
            {
                modalTitle: 'Upload Custom Column Names for ' + props?.data?.name,
                modalContent: <CrmMappingUploadModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'lg CrmMappingUploadModal',
            },
        ],
        deleteSite: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete',
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will freeze the selected Site(s) from edits while they remain active and will remove them from data tables and exports. The System Admin can restore soft-deleted Sites or can permanently purge them to deactivate them and delete their analytics.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        bulkDeleteSite: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <BulkSoftDeleteSite hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete',
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will freeze the selected Site(s) from edits while they remain active and will remove them from data tables and exports. The System Admin can restore soft-deleted Sites or can permanently purge them to deactivate them and delete their analytics.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        deleteReport: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent: <DeleteActions hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will freeze the selected Report(s) from edits while they remain active and will remove them from data tables and exports. The System Admin can restore soft-deleted Reports or can permanently purge them to deactivate them and delete their analytics.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        cancelUserSetting: (props) => [
            {
                modalTitle: 'Cancel User Setting',
                modalContent: 'Are you sure you want to cancel?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        editMembership: (props) => [
            {
                modalTitle: 'Edit Members of ' + props?.data?.title,
                modalContent: <EditMembership hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        CreateEditConfirmation: (props) => [
            {
                modalTitle:
                    props?.data?.title === 'CreateGroup'
                        ? 'Create Group Confirmation'
                        : props?.data?.title === 'EditGroup'
                        ? 'Edit Membership Confirmation'
                        : '',
                modalContent: <CreateEditConfirmation hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        newGroup: (props) => [
            {
                modalTitle: 'Create New Group',
                modalContent: <NewGroup hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        selectMembers: (props) => [
            {
                modalTitle: 'Select Members of  ' + props?.data.name.name,
                modalContent: <AddMember hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],

        renameGroup: (props) => [
            {
                modalTitle: 'Rename Group',
                modalContent: <RenameGroup hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
            },
        ],
        deleteGroup: (props) => [
            {
                modalTitle: 'Delete Group',
                modalContent: <DeleteGroup hideModal={props?.modalAction} data={props.data} />,
                modalButton: 'Delete Group',
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to delete this group it will be permanently removed from the system and unrecoverable.',
            },
        ],
        exportUsers: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportUsersDataModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Download',
                modalBodyClass: 'custom-modal-border',
                modalSize: 'small',
            },
        ],
        exportRecords: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportRecords hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportUserActivity: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportUserActivity hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFundDetails: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFundDetailsModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Download',
                modalBodyClass: 'custom-modal-border',
                modalSize: 'small',
            },
        ],
        exportFyPerformance: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyPerformance hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyDonors: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyDonors hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyImpacts: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyImpacts hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyDistributionList: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyDistributionList hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportSiteDistributionList: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportSiteDistributionList hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportFyReportsPrintBridge: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportFyReportsPrintBridge hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportRecordBasedDataAndSnapshots: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportRecordBasedDataAndSnapshots hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        exportSystemWideDashboardData: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportSystemWideDashboardData hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Depending on the number of records, the gallery could take a long time to render. Accordingly, you will receive an email notification when the file is compiled and ready for download.',
            },
        ],
        exportChangeLogData: (props) => [
            {
                modalTitle: 'Export System Log Data',
                modalContent: <ExportChangeLogData hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Depending on the number of records, the gallery could take a long time to render. Accordingly, you will receive an email notification when the file is compiled and ready for download.',
            },
        ],
        exportSystemWideSnapshotsData: (props) => [
            {
                modalTitle: 'Export Data',
                modalContent: <ExportSystemWideSnapshotsDataModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
                modalMessageNote: 'Note: ',
                modalMessageTextAlignmentLeft: true,
                modalMessageText:
                    'Depending on the number of snapshots, the gallery could take a long time to render. Accordingly, you will receive an email notification when the file is compiled and ready for download.',
            },
        ],
        exportArchiveData: (props) => [
            {
                modalTitle: 'Export Archive (Last 30 Days)',
                modalContent: <ExportArchiveData hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        deleteExportArchiveData: (props) => [
            {
                modalTitle: 'Delete Archived Export',
                modalContent: 'Are you sure you want to delete archived export permanently?',
                modalButton: 'Delete',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        sessionRecording: (props) => [
            {
                modalTitle: 'Session Recording',
                modalContent: <SessionRecording data={props?.data} url={props?.data?.url ? props?.data?.url : ''} />,
                modalSize: 'lg session-recording',
            },
        ],
        manageDownloads: (props) => [
            {
                modalTitle: 'Manage Record-Based FY Downloads',
                modalContent: <ManageDownloads hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg Manage-downloads-modal',
            },
        ],
        deleteDownloads: (props) => [
            {
                modalTitle: 'Delete Download',
                modalContent: 'Are you sure you want to delete record asset permanently?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        addReplaceDownloads: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ReplaceAssetModal />,
                modalSize: 'small',
            },
        ],
        createAccessKey: (props) => [
            {
                modalTitle: 'Access Key Created',
                modalContent: <CreateAccessKey hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalBodyClass: 'custom-modal-border',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Never post your secret access keys on any public platforms, such as Github. This can compromise your account security. This is the only time that the secret access key can be viewed. You cannot recover them later. However, you can recreate new access keys at any time.',
            },
        ],
        deleteAccessKey: (props) => [
            {
                modalTitle: 'Delete Access Key',
                modalContent: <DeleteAccessKey hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    "If you delete, any Ovrture API call made using this key will fail. Before you delete an access key, make sure that it's no longer in use. You cannot recover an access key after you delete it.",
                modalSize: 'small',
            },
        ],
        cancelBroadcast: (props) => [
            {
                modalTitle: 'Cancel Confirmation',
                modalContent: 'Are you sure you want to cancel creating this broadcast/alert?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        filterByBrand: (props) => [
            {
                modalTitle: 'Filter Alert To Specific Brands',
                modalContent: <FilterByBrand hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        removeAlert: (props) => [
            {
                modalTitle: 'Remove Confirmation',
                modalContent: 'Are you sure you want to remove this alert from the system?',
                modalButton: 'Remove',
                modalSize: 'small',
            },
        ],
        updateTimer: (props) => [
            {
                modalTitle: 'Update Timer',
                modalContent: <UpdateTimer hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        broadcastConfirmation: (props) => [
            {
                modalTitle: 'Broadcast',
                modalContent: 'Are you sure you want to broadcast this revised alert across the entire system?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        broadcastPublishMultiStepModal: (props) => [
            {
                modalTitle: 'Broadcast Alert',
                modalContent: <BroadcastPublishMultiStepForm hideModal={props?.modalAction} data={props.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'lg',
            },
        ],
        broadcastSetTimerConfirmation: (props) => [
            {
                modalTitle: 'Set a Timer To Remove Alert',
                modalContent: 'Got it. Would you like to determine when the alert should be removed?',
                modalButton: 'Yes; Set A Timer To Remove This Alert.',
                modalSize: 'small',
            },
        ],
        cropImage: (props) => [
            {
                modalTitle: 'Edit Image',
                modalInfoIcon: true,
                modalInfoMsg:
                    "This tool allows you to fit images into the aspect ratio required by the site design. Click and drag to move the window. Use either the slider or pinch in/out to zoom and use alt text to briefly describe the image's essential elements and why it is present.",
                modalContent: (
                    <ImageCropper
                        hideModal={props?.modalAction}
                        image={props?.data?.image}
                        setCropData={props.data.setCropData}
                        filename={props.data.filename}
                        name={props?.data?.name}
                        otherProps={props?.data}
                    />
                ),
                modalSize: 'small image-cropper-modal',
            },
        ],
        setBroadcastTimer: (props) => [
            {
                modalTitle: 'Set Broadcast Timer',
                modalContent: <SetBroadcastTimer data={props?.data} />,
            },
        ],
        restoreRecords: (props) => [
            {
                modalTitle: ' Restore Records',
                modalContent:
                    props?.data?.name === 'bulkRestoreDeletedRecords'
                        ? 'Are you sure you want to restore this/these record(s)?'
                        : 'Are you sure you want to restore this record?',
                modalButton: 'Restore',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
            },
        ],
        restoreActions: (props) => [
            {
                modalTitle: 'Owner Declaration ',
                modalContent: <RestoreActions hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreDeletedDataActionModal: (props) => [
            {
                modalTitle: 'Restore',
                modalContent: <RestoreDeletedDataActionModal hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreOriginalOwner: (props) => [
            {
                modalTitle: 'Owner Selection',
                modalContent: <RestoreOriginalOwner hideModal={props?.modalAction} data={props.data.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        restoreDelete: (props) => [
            {
                modalTitle: 'Delete System',
                modalContent:
                    'The following system were deleted by the platform admin.This function allows you to restore these systems or permanently delete them from the database',
                modalButton: 'Process',
                modalSize: 'small',
            },
        ],
        brandAdminSettingConfirmation: (props) => [
            {
                modalTitle: props.data.name === 'barndAdminConfirmation' ? 'Brand Administration' : 'Brand Access',
                modalContent: <BrandAdminSetting hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props.data.name === 'brandAllUserConfirmation'
                        ? "Clicking 'yes' will overwrite all previous access settings for this brand."
                        : props.data.name === 'barndAdminConfirmation'
                        ? 'Clicking "yes" will overwrite all previous access administrator settings for this brand.'
                        : props.data.name === 'brandSelectGroup' || props.data.name === 'brandSelectUser'
                        ? " Clicking 'yes' will overwrite all previous access settings for this brand."
                        : ' ',
                modalBodyClass: 'custom-modal-border',
            },
        ],
    },
    deletedData: {
        bulkWarning: (props) => [
            {
                modalTitle: props?.data?.modalTitle,
                modalContent: props?.data?.modalContent,
                modalButton: props?.data?.modalButton,
                modalMessageWarning: 'Warning: ',
                modalMessageText: props?.data?.modalMessageText,
                modalSize: 'small custom-warning',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        selectAssetFolder: (props) => [
            {
                modalTitle: 'Select Asset Folder',
                modalContent: <SelectAssetFolderModel data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
    },
    changeLog: {
        changeLogCommonModule: (props) => [
            {
                modalTitle: 'Change Log',
                modalContent: (
                    <ChangeLogCommonModule hideModal={props?.modalAction} data={props?.data} name="change-log" />
                ),
                modalSize: 'xl',
                modalResize: 'modal-resize',
                modalCancelButton: 'Cancel',
                modalBodyClass: 'change-log-modal',
            },
        ],
        changeLogViewDetails: (props) => [
            {
                modalTitle: 'Updated Details',
                modalContent: (
                    <ChangeLogViewDetails hideModal={props?.modalAction} data={props?.data} name="change-log" />
                ),
                modalSize: 'lg',
                modalResize: 'modal-resize',
                modalCancelButton: 'Cancel',
                modalBodyClass: 'change-log-modal',
            },
        ],
        changeLogCommonModulePlatform: (props) => [
            {
                modalTitle: 'Change Log Platform',
                modalContent: (
                    <ChangeLogCommonModulePlatform
                        hideModal={props?.modalAction}
                        data={props?.data}
                        name="change-log"
                    />
                ),
                modalSize: 'xl',
                modalResize: 'modal-resize',
                modalCancelButton: 'Cancel',
                modalBodyClass: 'change-log-modal',
            },
        ],
    },
    reportData: {
        fundPreview: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'fund-preview'
                        ? 'Fund Preview'
                        : props?.data?.type === 'impact-preview'
                        ? 'Impact Preview'
                        : 'Fund Performance Preview',
                modalContent: <FundPreviewModal data={props?.data} hideModal={props?.modalAction} />,
                modalButton: 'Back',
                modalSize: 'xl preview fundPreviewModal',
            },
        ],
        viewLinkedRecords: (props) => [
            {
                modalTitle: 'Linked Records',
                modalContent: <LinkedRecords hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Close',
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        impactSort: (props) => [
            {
                modalTitle: 'Impact Sort',
                modalContent: <ImpactSortModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Sort',
                modalSize: 'lg',
            },
        ],
        deleteFunds: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent:
                    props?.data?.type === 'fund-details-hard-delete' ||
                    props?.data?.type === 'fund-delete-bulk' ||
                    props?.data?.type === 'fund-performance-delete-bulk' ||
                    props?.data?.type === 'fund-donors-delete-bulk' ||
                    props?.data?.type === 'fund-performance-hard-delete' ||
                    props?.data?.type === 'fund-impact-hard-delete' ||
                    props?.data?.type === 'fund-donors-hard-delete'
                        ? 'Are you sure you want to delete this data permanently? Once deleted, this data (and any and all associated elements) can not be retrieved.'
                        : props?.data?.type === 'fund-details'
                        ? 'Are you sure you want to delete the selected Fund Details data?'
                        : 'Are you sure you want to delete the selected FY Performance data?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.type === 'fund-details-hard-delete' ||
                    props?.data?.type === 'fund-delete-bulk' ||
                    props?.data?.type === 'fund-performance-delete-bulk' ||
                    props?.data?.type === 'fund-donors-delete-bulk' ||
                    props?.data?.type === 'fund-performance-hard-delete' ||
                    props?.data?.type === 'fund-donors-hard-delete' ||
                    props?.data?.type === 'fund-impact-hard-delete'
                        ? 'This action is irreversible; proceed with caution.'
                        : props?.data?.type === 'fund-details'
                        ? `Proceeding will remove the selected Fund(s) from Reports, data tables, and exports and will de-link them from donor Records. The System Admin can restore or permanently purge Funds. Upon restoration, Funds must be re-linked with Records by a User.`
                        : 'Proceeding will remove the selected FY Performance data from Reports, data tables, and exports. The System Admin can restore or permanently purge FY data.',
            },
        ],
        restoreFunds: (props) => [
            {
                modalTitle: 'Restore',
                modalContent: 'Are you sure you want to restore?',
                modalButton: 'Restore',
                modalSize: 'small',
            },
        ],
        addDonor: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: (
                    <AddDonor
                        titile="Please enter an existing record reference number to add new donor in list:"
                        hideModal={props?.modalAction}
                        data={props?.data}
                    />
                ),
                modalButton: 'Add',
                modalSize: 'lg',
            },
        ],
        deleteDonor: (props) => [
            {
                modalTitle: 'Delete FY Donor(s)',
                modalContent: 'Are you sure you want to delete the selected FY Donors data?',
                modalButton: 'Delete',
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will remove the selected FY Donors data from Reports, data tables, and exports. The System Admin can restore or permanently purge FY data.',
            },
        ],
        deleteImpact: (props) => [
            {
                modalTitle: 'Delete Fund Impact',
                modalContent: 'Are you sure you want to delete the selected FY Impact data?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will remove the selected FY Impact data from Reports, data tables, and exports. The System Admin can restore or permanently purge FY data.',
            },
        ],
        addNewFyData: (props) => [
            {
                modalTitle: 'Add Fiscal Year',
                modalContent: <AddNewFyData />,
                modalButton: 'Add',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
    },
    mfa: {
        mfaWarning: (props) => [
            {
                modalTitle: 'MFA Disable',
                modalContent: 'Are you sure you want to disable MFA for yourself?',
                modalButton: 'Yes',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        resetMFA: (props) => [
            {
                modalTitle: 'MFA Setup',
                modalContent: <ResetMFAModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'mfa',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        selectContentPanel: (props) => [
            {
                modalTitle: 'Select Content Panel',
                modalContent: <SelectContentPanelModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
    },
    common: {
        infoModal: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
                modalButton: 'Ok',
            },
        ],
        resetPasswordCommon: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: <ResetPasswordCommon hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg link-fund-modal',
                modalBodyClass: 'link-fund',
            },
        ],
        linkFunds: (props) => [
            {
                modalTitle:
                    (props?.data?.type === 'report-build' && props?.data?.page === 'potfolio') ||
                    (props?.data?.type === 'report-build' && props?.data?.page === 'multireports')
                        ? 'Select Funds for Report'
                        : props?.data?.type === 'report-update' || props?.data?.type === 'reportTabLinkFund'
                        ? props?.data?.title
                        : props?.data?.type === 'report-edit-page'
                        ? props?.data?.title + ' - Funds'
                        : props?.data?.username
                        ? `${props?.data?.username}'s - Funds`
                        : `Funds`,
                modalInfoIcon:
                    props?.data?.type === 'report-update' || props?.data?.type === 'reportTabLinkFund' ? false : true,
                modalInfoMsg:
                    props?.data?.type === 'report-build' && props?.data?.page === 'potfolio'
                        ? 'Linking this Report Updates “tab” to specific funds will dictate which funds are pulled into the report for this specific “tab.”'
                        : 'Linking this record to specific funds will dictate what funds are pulled when/if a Report is built for this record.',
                modalContent: <LinkFunds hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg link-fund-modal',
                modalBodyClass: 'link-fund',
            },
        ],
        multiStepResetPassword: (props) => [
            {
                modalTitle: 'Reset Passcode',
                modalContent: <MultiStepResetPassword hideModal={props?.modalAction} data={props?.data} />,
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        selectContentPanel: (props) => [
            {
                modalTitle: 'Select Content Panel',
                modalContent: <SelectContentPanelModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
        replaceAsset: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ManageDownloadReplaceAssetModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-asset',
            },
        ],
        replaceAssetEditorCommon: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ReplaceAssetEditorModalCommon data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'lg',
                modalBodyClass: 'replace-asset-editor',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Compatible file types include the commonly used image, video, and document files. Images can not be uploaded that exceed 32 megabytes. Videos can not be uploaded that exceed 1 gigabyte. Learn more in the Compatible Asset File Types support article.',
            },
        ],
        btdGreetingMessage: (props) => [
            {
                modalTitle:
                    (props?.data?.siteOrReport === 'site' ? 'Sites - ' : 'Reports - ') +
                    props?.data?.brandName +
                    ' - Default Greeting Message',
                modalContent: <BtdGreetingMessage hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg default-greeting-message',
            },
        ],
        btdGreetingImage: (props) => [
            {
                modalTitle: 'Select Default Greeting Image',
                modalContent: <BtdGreetingImage hideModal={props?.modalAction} data={props?.data} />,
                modalSize: ' default-header-image-modal',
            },
        ],
        deleteFundDetails: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'fund-details'
                        ? 'Delete Fund Details'
                        : props?.data?.type === 'fund-performance'
                        ? 'Delete FY Performance'
                        : props?.data?.type === 'fund-donors'
                        ? 'Delete FY Donors'
                        : 'Delete FY Impact',
                modalContent: 'Are you sure you want to delete?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'This action could affect active reports and will be irrevocable as all data will be permanently removed from the database.',
            },
        ],
        BulkDeleteFund: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent:
                    props?.data?.type === 'fund-details-hard-delete' || props?.data?.type === 'fund-delete-bulk'
                        ? 'Are you sure you want to delete this data permanently? Once deleted, this data (and any and all associated elements) can not be retrieved.'
                        : 'Deleting this fund will purge fund data (details, performance, and impact) from the system for all fiscal years. Furthermore, deleting this fund will retroactively remove all fund panels within all reports that reference this fund. Are you sure you want to proceed?',
                modalButton: 'Delete',
                modalSize: 'small custom-warning',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.type === 'fund-details-hard-delete' || props?.data?.type === 'fund-delete-bulk'
                        ? 'This action is irreversible; proceed with caution.'
                        : 'By proceeding with this action you will delete all fund details and subordinate data (performance, donors, and impact) for all fiscal years. Any existing report that references this data will be affected.',
            },
        ],
        BulkRestoreFund: (props) => [
            {
                modalTitle: 'Restore',
                modalContent: 'Are you sure you want to restore?',
                modalButton: 'Delete',
                modalSize: 'small',
            },
        ],
        preflightData: (props) => [
            {
                modalTitle: props.data.isNotification ? 'Data Upload Results' : 'Data Upload Pre-Flight Results',
                modalContent: <PreflightData hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to upload/cancel without first downloading the annotated spreadsheet, you can access it from the System Alerts at a later time.',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        importData: (props) => [
            {
                modalTitle: 'Data Upload Results',
                modalContent: <ImportData hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to close this window without first downloading the failed rows, you can access the failed rows from the System Alerts at a later time.',
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        selectBrand: (props) => [
            {
                modalTitle: 'Select Brand',
                modalContent: <SelectBrand hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        buildSites: (props) => [
            {
                modalTitle: 'Create Sites Confirmation',
                modalContent:
                    'Are you sure you are ready to build these sites? Once built they will be written to each record.',
                modalButton: 'Build',
                modalSize: 'small',
            },
        ],
        buildReports: (props) => [
            {
                modalTitle: 'Create Reports Confirmation',
                modalContent:
                    'Are you sure you are ready to build these reports? Once built, they will be written to each record where they can only be edited individually.',
                modalButton: 'Build',
                modalSize: 'small',
            },
        ],
        buildReportWithReportUpdateOn: (props) => [
            {
                modalTitle: 'Report Update',
                modalContent: <BuildReportWithReportUpdateOnModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        selectFundReportWithReportUpdateOn: (props) => [
            {
                modalTitle: 'Report Update | Select Funds per Record',
                modalContent: (
                    <SelectFundReportWithReportUpdateOnModal hideModal={props?.modalAction} data={props?.data} />
                ),
                modalSize: 'xl',
                modalResize: 'modal-fullsize selectFundReportWithReportUpdateOn',
            },
        ],
        buildReport: (props) => [
            {
                modalTitle: 'Build ' + props?.data?.year + ' Report For ' + props?.data?.username,
                modalContent: <BuildReport hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        progressBar: (props) => [
            {
                modalTitle: 'Step 1  of 2: Uploading...',
                modalContent: <ProgressBarStatus hideModal={props?.modalAction} />,
                modalSize: 'small',
            },
        ],
        newFolder: (props) => [
            {
                modalTitle: 'Add Folder',
                modalContent: <NewFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small folder-add',
            },
        ],
        renameFolder: (props) => [
            {
                modalTitle: 'Rename Folder',
                modalContent: <RenameFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small folder-rename',
            },
        ],
        deleteFolder: (props) => [
            {
                modalTitle: props?.data?.softDelete
                    ? 'Soft Delete Panel'
                    : props?.data?.name === 'folderDelete'
                    ? 'Delete Folder'
                    : ' Delete Content Panel',
                modalContent: <DeleteFolder hideModal={props?.modalAction} data={props.data} />,
                modalSize: 'small',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    props?.data?.name === 'folderDelete'
                        ? 'If you elect to delete this folder, the folder and its contents will be permanently removed from the system and unrecoverable.'
                        : 'Proceeding will remove the selected Content Panel(s) from cloud folders. The System Admin can restore them to their folders or permanently remove them; however, they will still be present in existing Sites/Reports.',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        changeLocation: (props) => [
            {
                modalTitle:
                    props?.data?.pageName === 'edit-content-panel' ? 'Select Publish Location' : 'Select New Location',
                modalContent: <SelectNewLocationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        changeLocationConfirmation: (props) => [
            {
                modalTitle: 'Move',
                modalContent: <ChangeLocationConfirmationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        changeLocationAsset: (props) => [
            {
                modalTitle: 'Select New Location',
                modalContent: <ChangeLocationModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small asset-move',
            },
        ],
        renameContentPanel: (props) => [
            {
                modalTitle: 'Rename Content Panel',
                modalContent: <RenameContentPanel hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small content-panel-rename',
            },
        ],
        duplicateContentPanel: (props) => [
            {
                modalTitle: 'Duplicate Content Panel',
                modalContent: <DuplicateContentPanelModal hideModal={props?.modalAction} data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText: 'Once the type of Content Panel is selected, this selection cannot be changed.',
            },
        ],
        contentPanelPreview: (props) => [
            {
                modalTitle: props?.data?.data?.text || props?.data?.data?.name,
                modalContent: <ContentPanelPreview data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'xl preview',
            },
        ],

        assetPanelPreview: (props) => [
            {
                modalTitle: props?.data?.data?.text
                    ? 'Asset Preview > ' + props?.data?.data?.text
                    : 'Asset Preview > ' + props?.data?.data?.name,
                modalContent: <AssetPanelPreview hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg asset-preview',
            },
        ],
        assetUpload: (props) => [
            {
                modalTitle: 'Upload',
                modalContent: <AssetUploadModal hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Compatible file types include the commonly used image, video, and document files. Images can not be uploaded that exceed 32 megabytes. Videos can not be uploaded that exceed 1 gigabyte. Learn more in the Compatible Asset File Types support article.',
            },
        ],
        renameAsset: (props) => [
            {
                modalTitle: 'Rename Asset Modal',
                modalContent: <RenameAsset hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small asset-rename',
            },
        ],
        deleteAsset: (props) => [
            {
                modalTitle: 'Delete Asset',
                modalContent: 'Are you sure you want to delete the selected Assets?',
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'Proceeding will remove the selected Asset(s) from cloud folders. The System Admin can restore them to their folders or permanently remove them; however, they will still be present in existing Content Panels and Sites/Reports.',
                modalSize: 'small custom-warning',
            },
        ],
        magicLinkCopyUrl: (props) => [
            {
                modalTitle: 'Copy Magic Link URL',
                modalContent: <MagicLinkCopyUrlModal data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'The Donor-Facing URL (Analytics Enabled) will not work until an Admin has approved it. Please wait for approval before sharing the URL.',
            },
        ],
        deleteCP: (props) => [
            {
                modalTitle: 'Delete Content Panel',
                modalContent: 'Are you sure you want to delete this panel?',
                modalButton: 'Delete',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'If you elect to delete this panel, it will be permanently removed from the system and unrecoverable.',
                modalSize: 'small custom-warning',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        selectTemplate: (props) => [
            {
                modalTitle: 'Select Template',
                modalContent: 'Content Body with JS Tree & preview// TBD',
                modalButton: 'Select',
                modalSize: 'small',
            },
        ],
        selectTemplateWithPreview: (props) => [
            {
                modalTitle: 'Select Template',
                modalContent: <SelectTemplateWithPreviewModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Select',
                modalSize: 'lg select-template-with-preview-modal',
            },
        ],
        cancelEditContentPanel: (props) => [
            {
                modalTitle: 'Cancel Editing Content Panel',
                modalContent: 'Are you sure you want to cancel editing this content panel?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        saveDraftModal: (props) => [
            {
                modalTitle: 'Save Draft',
                modalContent: <SaveDraftModal hideModal={props?.modalAction} data={props?.data} />,
                modalMessageNote: 'Note: ',
                modalMessageText: 'Once saved as a draft, this content panel will be accessible via the Drafts folder.',
                modalSize: 'small',
            },
        ],
        publishEditedContentPanel: (props) => [
            {
                modalTitle: 'Publish Edited Content Panel',
                modalContent: 'Are you sure you want to publish this edited content panel?',
                modalButton: 'Publish',
                modalCancelButton: 'Cancel',
                modalSize: 'small',
            },
        ],
        publishEditedContentPanelMultiStepModal: (props) => [
            {
                modalTitle: 'Content Panel',
                modalContent: (
                    <PublishEditedContentPanelMultiStepForm hideModal={props?.modalAction} data={props.data} />
                ),
                modalBodyClass: 'custom-modal-border multi-step',
                modalSize: 'small',
            },
        ],
        contentPanelAccess: (props) => [
            {
                modalTitle: 'Content Panel Access',
                modalContent:
                    props?.data?.action === 'selectGroups' || props?.data?.active === 2
                        ? 'Are you sure you want to control access to this content panel to these selected groups?'
                        : props?.data?.action === 'selectUsers' || props?.data?.active === 3
                        ? 'Are you sure you want to control access to this content panel to these selected users?'
                        : 'Are you sure you want to control access to this content panel to all users?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalMessageNote: 'Note: ',
                modalMessageText: 'Clicking "yes" will overwrite all previous access settings for this content panel.',
                modalSize: 'small custom-warning',
            },
        ],
        sendNotification: (props) => [
            {
                modalTitle: 'Send Notification',
                modalContent:
                    'Would you like to send a notification to all users that this content panel has been created?',
                modalButton: 'Send Notification',
                modalCancelButton: 'Do Not Send Notification',
                modalCancelButtonAction: 'true',
                modalSize: 'small',
            },
        ],
        saveDraft: (props) => [
            {
                modalTitle: 'Save Draft',
                modalContent: 'How do you want to proceed?',
                modalButton: 'Save Draft & Exit',
                modalCancelButton: 'Cancel',
                modalMessageNote: 'Note: ',
                modalMessageText: 'Once saved as a draft, this content panel will be accessible via the Drafts folder.',
                modalSize: 'small custom-warning',
            },
        ],
        assetControlAccess: (props) => [
            {
                modalTitle: 'Asset Control Access',
                modalContent:
                    props?.data?.action === 'selectGroups' || props?.data?.active === 2
                        ? 'Are you sure you want to provide access to this asset to these selected groups?'
                        : props?.data?.action === 'selectUsers' || props?.data?.active === 3
                        ? 'Are you sure you want to provide access to this asset to these selected users?'
                        : 'Are you sure you want to provide access to this asset to all users?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalMessageNote: 'Warning: ',
                modalMessageText: 'Clicking "yes" will overwrite all previous access settings for this asset.',
                modalSize: 'small custom-warning',
            },
        ],
        copyUrl: (props) => [
            {
                modalTitle: 'Copy URL',
                modalContent: <CopyUrl data={props?.data} />,
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'The Donor-Facing URL (Analytics Enabled) will not work until an Admin has approved it. Please wait for approval before sharing the URL.',
            },
        ],
        allSnapshots: (props) => [
            {
                modalTitle:
                    props?.data?.type === 'analytics-page' ? `${props?.data?.recordName}'s History` : 'All Activities',
                modalInfoIcon: true,
                modalInfoMsg:
                    'This table represents all of the snapshots (i.e. versions) of this site/report. A baseline snapshot is triggered upon original creation; follow-on snapshots are built when a change is made, and an external login occurs.',
                modalContent: <Snapshots hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'xl',
                modalResize: 'modal-resize',
            },
        ],
        editorsList: (props) => [
            {
                modalTitle: 'Change Log',
                modalContent: <EditorsList hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        deleteSnapshot: (props) => [
            {
                modalTitle: 'Delete Confirmation',
                modalContent:
                    'Are you sure you want to delete session permanently? Doing so will discard all record of this external login.',
                modalButton: 'Delete',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        leavingRouteMsg: (props) => [
            {
                modalTitle: 'Unsaved Changes',
                modalContent: <PrompDialougeModal data={props?.data} />,
                modalSize: 'md',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        duplicateSite: (props) => [
            {
                modalTitle: 'Duplicate Site',
                modalContent: <DuplicateModal hideModal={props?.modalAction} data={props?.data} />,
                modalButton: 'Close',
                modalSize: 'lg',
                modalResize: 'modal-resize',
            },
        ],
        apiConnection: (props) => [
            {
                modalTitle: props?.data?.modalTitle,
                modalContent: props?.data?.modalContent,
                modalButton: props?.data?.modalButton,
                modalMessageWarning: 'Note: ',
                modalMessageText: props?.data?.modalMessageText,
                modalSize: 'small custom-warning',
                modalCancelButton: 'No',
            },
        ],
        apiConnectionDisconnect: (props) => [
            {
                modalTitle: props?.data?.modalTitle,
                modalContent: props?.data?.modalContent,
                modalButton: props?.data?.modalButton,
                modalMessageWarning: 'Note: ',
                modalMessageText: props?.data?.modalMessageText,
                modalSize: 'small custom-warning',
                modalCancelButton: 'Cancel',
            },
        ],
        configureData: (props) => [
            {
                modalTitle: props?.data?.modalTitle,
                modalContent: (
                    <ConfigureDataModal id={props?.data?.id} hideModal={props?.modalAction} data={props?.data} />
                ),
                modalSize: 'md crm-api-connection-data-flow',
            },
        ],
    },
    systemNotification: {
        networkError: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
                modalButton: 'Delete',
                modalSize: 'small connection-error',
            },
        ],
        sessionExpired: (props) => [
            {
                modalTitle: props?.data?.title,
                modalContent: props.data.content,
            },
        ],
    },
    editor: {
        addOrChangeSlide: (props) => [
            {
                modalTitle: 'Slide Layout',
                modalContent: <AddOrChangeSlide hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'lg',
            },
        ],
        changeSlideConfirmation: (props) => [
            {
                modalTitle: 'Change Slide Layout',
                modalContent:
                    'If you elect to move forward, all content from the previous slide will be lost. Are you sure you want to proceed?',
                modalButton: 'Proceed',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        printBridgeHeading: (props) => [
            {
                modalTitle: 'Document Heading',
                modalContent: <PrintBridgeHeading hideModal={props.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        printWarning: (props) => [
            {
                modalTitle: 'Document/Video Exist',
                modalContent: 'Are you sure you want to print?',
                modalMessageWarning: 'Warning: ',
                modalMessageText:
                    'This content panel contains video(s) or downloadable document(s) that will render as icons. We recommend you replace these assets with photos before printing.',
                modalButton: 'Print',
                modalCancelButton: 'Cancel/Replace',
                modalSize: 'small',
            },
        ],
        printPreview: (props) => [
            {
                modalTitle: 'Print/Download Preview',
                modalContent: <PrintPreview hideModal={props.modalAction} data={props?.data} />,
                modalSize: 'lg',
                modalBodyClass: 'print-preview-modal',
            },
        ],
        NavigationModal: (props) => [
            {
                modalTitle: 'Navigation Panel',
                modalContent: <NavigationModal hideModal={props.modalAction} data={props?.data} />,
                modalSize: 'xl',
                modalBodyClass: 'navigation',
            },
        ],
        delete: (props) => [
            {
                modalTitle:
                    props?.data?.name === 'panel'
                        ? 'Delete Content Panel'
                        : props?.data?.name === 'slide'
                        ? 'Delete Slide Layout'
                        : 'Delete Report Update',
                modalContent:
                    'Are you sure you want to delete this ' +
                    (props?.data?.name === 'panel'
                        ? 'panel'
                        : props?.data?.name === 'slide'
                        ? 'slide'
                        : 'report update') +
                    '?',
                modalButton: 'Delete',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        changeCoverImage: (props) => [
            {
                modalTitle: 'Select Greeting Image',
                modalContent: <ChangeCoverImage hideModal={props.modalAction} data={props?.data} />,
                modalSize: 'small',
            },
        ],
        addContentPanel: (props) => [
            {
                modalTitle: 'Add Content Panel',
                modalContent: <AddContentPanelModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'select-content-panel',
            },
        ],
        brandChange: (props) => [
            {
                modalTitle: 'Brand Change',
                modalContent: 'Are you sure you want to change this brand?',
                modalButton: 'Apply',
                modalSize: 'small',
                modalActionOnClickCancelButton: props?.data?.modalActionOnClickCancelButton,
            },
        ],
        exitEditor: (props) => [
            {
                modalTitle: 'Do you want to exit?',
                modalContent: 'Any unsaved changes will be lost. Do you want to continue?',
                modalButton: 'Yes',
                modalCancelButton: 'No',
                modalSize: 'small',
            },
        ],
        printReportElements: (props) => [
            {
                modalTitle: 'Print Report Elements',
                modalContent: <PrintReportElements hideModal={props?.modalAction} data={props?.data} />,
                modalSize: 'small',
                modalBodyClass: 'custom-modal-border',
            },
        ],
        replaceAssetEditor: (props) => [
            {
                modalTitle: 'Replace Asset',
                modalContent: <ReplaceAssetEditorModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'lg',
                modalBodyClass: 'replace-asset-editor',
                modalMessageNote: 'Note: ',
                modalMessageText:
                    'Compatible file types include the commonly used image, video, and document files. Images can not be uploaded that exceed 32 megabytes. Videos can not be uploaded that exceed 1 gigabyte. Learn more in the Compatible Asset File Types support article.',
            },
        ],
        replaceScriptCode: (props) => [
            {
                modalTitle: 'Insert Embed Code',
                modalContent: <ReplaceScriptCodeModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'lg',
                modalBodyClass: 'replace-script-code',
            },
        ],
        replaceEmbedVideo: (props) => [
            {
                modalTitle: 'Linked Asset',
                modalContent: <EmbedVideoModal data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'replace-embed-video',
            },
        ],
        oneEditorWarning: (props) => [
            {
                modalTitle: 'Access Restricted',
                modalContent: <OneEditorWarning data={props?.data} hideModal={props?.modalAction} />,
                modalSize: 'small',
                modalBodyClass: 'one-editor-warning-modal',
            },
        ],
    },
};

export { modal };
